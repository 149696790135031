@import "_vars";
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import "charactercard";
@import "header";
@import "maintitle";
@import "comiccard";
@import "login";
@import "pagination";
@import "slider_checkbox";
@import "searchbar";
@import "counter";
@import "homepage";
@import "loan_comic";
@import "home_showtop";
@import "edit_user";
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'ComicBook';
  font-style: normal;
  font-weight: 400;
  src: local('Comic Book Regular'), local('Comic-Regular'), url(../font/Comic_Book.otf) format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
@font-face {
  font-family: 'Marvel';
  font-style: normal;
  font-weight: 400;
  src: url(../font/marvel_regular.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Marvelheroes';
  font-style: normal;
  font-weight: 400;
  src: url(../font/marvel_heroes.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}


body{
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
  background-image: url('../img/bg/bg_comic_characters_bottom_nb.png');
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: unset;
}

#content{
  box-shadow:  0px 10px 5px 0px rgba(0,0,0,0.6) inset;
}

h1,h2,h3,h4,h5 {
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
  color: $font-primary-color;
}

h1 {
  color: $font-primary-color;
}
.header-title-min{
  font-size: calc(7vw + 15px);
  transform: rotate(0deg);
}
.header-title{
  font-size: calc(7vw + 15px);
  transform-origin: bottom;
  padding-top-top: 20vh;
  transform: rotate(0deg);
}
h2{
  font-size: calc(3vw + 18px);
  &.title-dark {
    color : $marvel-primary
  }
}

h4{
  font-size: calc(.5vw + 22px);
}

.borderless td, .borderless th {
  border: none;
}

.marvel-title h5 {
  font-size: calc(.1vw + 10px);
}

h5.secondary-color, h4.secondary-color, h3.secondary-color {
  color: $bg-secondary;
}

h5.ternary-color, h4.ternary-color{
  color: $marvel-primary;
}

.img-rate-icon{
  min-width: 50px;
  width: 50px;
  height: auto;
}
.avatar-xl {
  min-width: 10vw;
  width: 10vw;
  height: auto;
}

p {
  font-size: calc(.2vw + 16px);
  font-family: 'Lato', sans-serif;
}

p.simple-font{
  font-size: calc(.2vw + 13px);
  font-family: 'Lato', sans-serif;
}


a,a:visited,a:hover,a:active{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:relative;
/*  transition:0.5s color ease;*/
  text-decoration:none;
  color:$font-secondary-color;
  font-size:1em;
}
a:hover{
  color:$font-primary-color;
}
a.before:before,a.after:after{
  content: "";
  transition:0.5s all ease;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:absolute;
}
a.before:before{
  top:-0.15em;
}
a.after:after{
  bottom:-0.15em;
}
a.before:before,a.after:after{
  height:2px;
  height:0.20rem;
  width:0;
  background:$font-primary-color;
}
a.first:after{
  left:0;
}
a.second:after{
  right:0;
}
a.third:after,a.sixth:before,a.sixth:after{
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
}
a.fourth:before,a.fourth:after{
  left:0;
}
a.fifth:before,a.fifth:after{
  right:0;
}
a.seventh:before{
  right:0;
}
a.seventh:after{
  left:0;
}
a.eigth:before{
  left:0;
}
a.eigth:after{
  right:0;
}
a.before:hover:before,a.after:hover:after{
  width:100%;

}
.square{
  box-sizing:border-box;
  margin-left:-0.4em;
  position:relative;
  font-size:2.5em;
  overflow:hidden;
}
.square a{
  position:static;
  font-size:100%;
  padding:0.2em 0.4em;
}
.square:before,.square:after{
  content: "";
  box-sizing:border-box;
  transition:0.25s all ease;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:absolute;
  width:5px;
  width:0.35rem;
  height:0;
  background:$font-primary-color;
}
.square:before{
  left:0;
  bottom:-0.2em;
}
.square.individual:before{
  transition-delay:0.6s;
}
.square:after{
  right:0;
  top:-0.2em;
}
.square.individual:after{
  transition-delay:0.2s;
}
.square a:before{
  left:0;
  transition:0.25s all ease;
}
.square a:after{
  right:0;
  transition:0.25s all ease;
}
.square.individual a:after{
  transition:0.25s all ease 0.4s;
}
.square:hover:before,.square:hover:after{
  height:calc(100% + 0.4em);
}
.square:hover a:before,.square:hover a:after{
  width:100%;
}


.hr-text {
  text-align: center;
  font-size: 16px;
  position: relative;
  border: 0;
  height: 0.5em;
  margin-top: 0.4em;
  margin-bottom: 0.1em;
  &:before {
    content: '';
    background: linear-gradient(to right, transparent, $font-secondary-color, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $font-primary-color;
    padding: 0 .5em;
    line-height: 0.1em;
    color: $font-primary-color;
  }
}
@mixin hr-comic {
  text-align: center;
  font-size: 16px;
  position: relative;
  border: 0;
  height: 0.5em;
  margin-top: 0.4em;
  margin-bottom: 0.1em;
  &:before {
    content: '';
    background: linear-gradient(to right, transparent, $marvel-primary, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $marvel-primary;
    padding: 0 .5em;
    line-height: 0.1em;
    color: $marvel-primary;
  }
}

.hr-text-color-2 {
  @include hr-comic;
  &:before {
    background: linear-gradient(to right, transparent, $marvel-primary, transparent);
  }
  &:after {
    color: $marvel-primary;
  }
}

.hr-text-color-3 {
  @include hr-comic;
  &:before {
    background: linear-gradient(to right, transparent, $font-primary-color, transparent);
  }
  &:after {
    color: $font-primary-color;
  }
}


.form-error-message{
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
  color: $font-primary-color;
  padding: 2px;
  display:inline;
  text-decoration: none;
  list-style-type: none;
}

.form-error-message-dark{
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
  color: $marvel-primary;
  padding: 2px;
  display:inline;
  text-decoration: none;
  list-style-type: none;
}
