@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #0868a8;
$white: #fff;
$black: #000;
$open-sans: 'Open Sans',
sans-serif;
// clear-fix mixin
@mixin cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

h1 .title {
  font-family: $open-sans;
  font-weight: 300;
}

.row {
  /*  max-width: 900px;*/
  margin: 50px auto 0;
}

#characters {
  // Base styles
  .card {
    cursor: pointer;
    background-color: $white;
    float: left;
    padding: 0 1.7rem;
    width: 50%;

    .menu-content {
      @include cf;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        font-weight: 700;
      }

      a {
        color: $white;
      }

      span {
        position: absolute;
        left: 50%;
        top: 0;
        font-size: 15px;
        font-weight: 700;
        font-family: 'Open Sans';
        transform: translate(-50%, 0);
      }
    }

    .wrapper {
      background-color: $white;
      min-height: 350px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);

      &:hover {
        .data {
          transform: translateY(0);
        }
      }
    }

    .data {
      position: absolute;
      bottom: 15%;
      width: 100%;
      transform: translateY(calc(70px + 1em));
      transition: transform 0.3s;

      .content {
        padding: 1em;
        position: relative;
        z-index: 1;
      }
    }

    .author {
      font-size: 12px;
    }

    .title {
      margin-top: 10px;
    }

    .text {
      height: 70px;
      margin: 0;
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox']:checked + .menu-content {
      transform: translateY(-60px);
    }
  }

  .character {

    .wrapper {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &:hover {
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
        background-blend-mode: color;

        .menu-content {
          span {
            transform: translate(-50%, -10px);
            opacity: 1;
          }
        }
      }
    }

    .header {
      @include cf;
      color: $white;
      padding: 1em;

      .date {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $bermuda;
        color: $white;
        padding: 0.8em;

        span {
          display: block;
          text-align: center;
        }

        .day {
          font-weight: 700;
          font-size: 24px;
          text-shadow: 2px 3px 2px rgba($black, 0.18);
        }

        .month {
          text-transform: uppercase;
        }

        .month,
        .year {
          font-size: 12px;
        }
      }
    }

    .menu-content {
      float: right;

      li {
        margin: 0 5px;
        position: relative;

      }

      span {
        transition: all 0.3s;
        opacity: 0;
      }
    }

    .data {
      color: $white;
      transform: translateY(calc(70px + 4em));
    }

    .title {
      a {
        color: $white;
      }
    }

    .button {
      display: block;
      width: 100px;
      margin: 2em auto 1em;
      text-align: center;
      font-size: 12px;
      color: $white;
      line-height: 1;
      position: relative;
      font-weight: 700;

      &::after {
        content: '\2192';
        opacity: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 0.3s;
      }

      &:hover {
        &::after {
          transform: translate(5px, -50%);
          opacity: 1;
        }
      }
    }
  }


  .creator {

    .wrapper {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &:hover {
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
        background-blend-mode: color;

        .menu-content {
          span {
            transform: translate(-50%, -10px);
            opacity: 1;
          }
        }
      }
    }

    .header {
      @include cf;
      color: $white;
      padding: 1em;
    }


    .data {
      bottom: 40%;
      color: $white;
      transform: translateY(calc(70px + 4em));
    }

    .title {
      a {
        color: $white;
      }
    }

    .button {
      display: block;
      width: 100px;
      margin: 2em auto 1em;
      text-align: center;
      font-size: 12px;
      color: $white;
      line-height: 1;
      position: relative;
      font-weight: 700;

      &::after {
        content: '\2192';
        opacity: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 0.3s;
      }

      &:hover {
        &::after {
          transform: translate(5px, -50%);
          opacity: 1;
        }
      }
    }
  }
}
