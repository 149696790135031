.jumbo-home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url('../img/bg/bg_spider.png');
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  margin-bottom: unset;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.6) inset;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h2{
      font-size: calc(3vw + 25px);
    }
  }
}



#btn-enter {
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
  font-size: calc(2vw + 18px) !important;
  border: 5px solid white;
  letter-spacing: .3vw;
  color: $font-primary-color;
  background-color: $marvel-primary;

}
