#loanComicAction{
  .img-loan {
    opacity: 0.5;
    width: 150px;
    height: auto;
  }
}

.modalrequest{
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
}
