$navbar-text-color : white;
$secondary-color : #212121;
$primary-color : #212121;
$navbar-height : 50px;
$marvel-primary : rgb(128, 16, 5);
$marvel-primary-darker : rgb(74, 14, 5);
$marvel-primary-opacity : rgba(128, 16, 5, .8);
$marvel-secondary-color : #b0aca5;
$font-primary-color : #fffaf0;
$font-secondary-color : #cbc7c0;
$button_hover_marvel : #c82333;
$button_button_hover_marvel : #bd2130;
$bg-secondary : #343a40;
