.pagination {
  font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
  color: $font-primary-color;


  .page-item.active .page-link {
    background-color: $button_hover_marvel;
    color: $font-secondary-color;
    font-weight: bold;
    border-style: none;
    cursor: pointer;
    opacity: 1;
  }
  .page-item {
    background-color: $marvel-primary;
    color: $font-secondary-color;
    .page-link {
      background-color: $marvel-primary;
      color: $font-secondary-color;
      opacity: .6;
      transition: all .5s ease-out;
      &:hover{
        opacity: 1;
        background-color: $button_hover_marvel;
        transition: all .5s ease-out;
      }
    }

  }
  .disabled {
    background-color: $marvel-secondary-color;
    color:$primary-color
  }
}
