#login {
  height: 100vh;
  position: relative;
  z-index: 1;

  label, a, .btn-login-sign {
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    color: $font-primary-color ;

  }
  .btn-login-sign {
    &:hover {
      color: $primary-color ;
    }
  }

  a {
    font-size: 1.5rem;
  }
  .row {
    height: 100vh;
  }


  .img-login{
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-image: url('../img/pics/bg_space2.jpg');
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .4;
    width: 100%;
  }

  .card-login {
    background-color: $marvel-primary-opacity;
    top:50%;
    transform: translateY(-50%);
  }
}
