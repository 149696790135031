@import url('https://fonts.googleapis.com/css?family=Inconsolata:700');

#searchbar {
  .container {
    position: relative;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 100px;

    .search {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 80px;
      background: $marvel-primary-opacity;
      border-radius: 50%;
      transition: all 1s;
      z-index: 4;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      // box-shadow: 0 0 25px 0 crimson;
      &:hover {
        cursor: pointer;
      }

      &::before {
        content: "";
        position: absolute;
        margin: auto;
        top: 22px;
        right: 0;
        bottom: 0;
        left: 22px;
        width: 12px;
        height: 2px;
        background: white;
        transform: rotate(45deg);
        transition: all .5s;
      }

      &::after {
        content: "";
        position: absolute;
        margin: auto;
        top: -5px;
        right: 0;
        bottom: 0;
        left: -5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid white;
        transition: all .5s;
      }
    }

    input {
      font-family: 'Inconsolata', monospace;
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 50px;
      outline: none;
      border: none;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      background: $marvel-primary-opacity;
      color: white;
      text-shadow: 0 0 10px $marvel-primary-opacity;
      padding: 0 80px 0 20px;
      border-radius: 30px;
      box-shadow: 0 0 25px 0 $marvel-primary-opacity,
      0 20px 25px 0 rgba(0, 0, 0, 0.2);
      // box-shadow: inset 0 0 25px 0 rgba(0, 0, 0, 0.5);
      transition: all 1s;
      opacity: 0;
      z-index: 5;
      font-weight: bolder;
      letter-spacing: 0.1em;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        width: 300px;
        opacity: 1;
        cursor: text;
      }

      &:focus ~ .search {
        right: -250px;
        background: #151515;
        z-index: 6;

        &::before {
          top: 0;
          left: 0;
          width: 25px;
        }

        &::after {
          top: 0;
          left: 0;
          width: 25px;
          height: 2px;
          border: none;
          background: white;
          border-radius: 0%;
          transform: rotate(-45deg);
        }
      }

      &::placeholder {
        color: white;
        opacity: 0.5;
        font-weight: bolder;
      }
    }
  }
}
#resultSearchBar{
  .result-text {
    font-size: calc(1vw + 16px);
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    position: relative;
    text-align: center;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: $font-primary-color;
    color: $primary-color;
    padding: 0 20px 0 20px;
    border-radius: 30px;
    box-shadow: 0 0 25px 0 $marvel-primary-opacity,
    0 20px 25px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: inset 0 0 25px 0 rgba(0, 0, 0, 0.5);
    transition: all 1s;
    z-index: 5;
    font-weight: bolder;
    letter-spacing: 0.1em;
  }
  .result-text-alert {
    font-size: calc(.3vw + 16px);
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    position: relative;
    text-align: center;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: $marvel-primary-opacity;
    color: $font-primary-color;
    padding: 0 20px 0 20px;
    border-radius: 30px;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.8), inset 1px -1px 7px 0px rgba(255, 253, 253, 1);

    transition: all 1s;
    z-index: 5;
    font-weight: bolder;
    letter-spacing: 0.1em;
  }
}
