.edit-user-section {
  .comic-loaner-card {
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    opacity: .9;
    min-height: 150px;
    transition: all 0.9s ease-out 0s;
    margin-top: 2rem;

    &.no-back-loaned {
      box-shadow: inset 0 0 0px 0px #fff, 0 0 12px #cbcbcb, 0 0 6px 5px #b92426, 0 0 25px #cb202e;
      transition: all 0.9s ease-out 0s;
    }

    img {
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0 0 4px 4px $primary-color;
      width: 9rem;
      height: 9rem;
      margin-top: 2rem;
    }

    p {
      margin: 0px;
      font-size: 16px;
      color: $secondary-color;

      &.small-display {
        font-size: calc(.3vw + 10px);
      }
    }


    .card-header {
      background-color: $bg-secondary;

      h5 {
        color: $font-primary-color;
      }

      .date-on-sale {
        color: white;
      }

    }

    .avatar-icon {
      min-width: 50px;
      width: 50px;
      height: auto;
    }

    .button-readmore {
      font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
      color: $font-primary-color;
      background-color: $marvel-primary;
    }

  }


}