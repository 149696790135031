.jumbo-fluid {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url('../img/bg/bg_town.png');
  background-color: rgba(0, 0, 0, 0.5);
  height: 40vh;
  margin-bottom: unset;
}

.jumbo-fluid.jumbo-full.active {
  box-shadow: 2px -55px 96px -70px rgba(227, 210, 20, 1) inset;
}

.jumbo-fluid.jumbo-full {
  height: 100vh;
}

#captainamerica {
  width: 30vw;
  height: auto;
  position: absolute;
  left: 60%;
  top: 80%;
  margin-right: 10vw;
}

#thor {
  width: 15vw;
  height: auto;
  position: absolute;
}

#deadpool {
  width: 25vw;
  height: auto;
  right: 80%;
  top: 100%;
  position: absolute;
}


#spiderman {
  width: 20vh;
  height: auto;
  position: absolute;
  left: 20%;
  top: 0%;
}

#ironman {
  width: 30vh;
  height: auto;
  position: absolute;
  top: 5%;
  right: 100%;
  /*
  margin-left: [-50% of your image's width];
    margin-top: [-50% of your image's height];
  */


}

header .title-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: auto;
  top: 40%;
}


$navbar-bgcolor : rgba(33, 33, 33, 0.3);
.navbar {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background-color: $navbar-bgcolor;

  .nav-link {
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    font-size: 18px;
  }

  .icon {
    font-family: 'Marvelheroes';
    font-size: 40px;
    padding: 3px;
  }

  .icon-fas-xl {
    color: $font-primary-color;
    font-size: 40px;
    padding: 3px;
  }

  .icon-fas {
    color: $font-primary-color;
    font-size: 40px;
    padding: 3px;
  }

  .icon-sm {
    font-size: 20px !important;
    margin: 0px;
  }

  .fas-navbar-xl {
    font-size: 47px;
  }

  .avatar-icon {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }

  .navbar-brand {
    height: $navbar-height;
  }
  .dropdown-menu {
    font-family: 'ComicBook', 'Helvetica Neue', Helvetica, Arial;
    background-color: $navbar-bgcolor;
    transition: all 0.2s ease-out;
    margin-top: 8px;
    border-width: 0px;
  }

  .dropdown-item {
    color : $font-primary-color;
    background-color: transparent;
    font-size: 18px;
    &:hover{
      background-color: transparent;
      color : $font-primary-color;
    }
  }

  .dropdown .dropdown-menu {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;

    color: $font-primary-color;

    border-radius: 0px;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
  }

  .dropdown.show .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
    max-height: 300px;
    opacity: 1;
  }
  .dropdown-toggle::after {
    display: none;
  }

  .nav-link > .dropdown-icon{
    font-size: 10px;
  }
}

.navbar-transparent {
  background-color: black;

  a:hover {
    background-color: unset;
  }
}

.bg-custom {
  background-color: $secondary-color;
  -webkit-box-shadow: -1px 9px 22px 0px rgba(255, 255, 255, 0.58);
  -moz-box-shadow: -1px 9px 22px 0px rgba(255, 255, 255, 0.58);
  box-shadow: -1px 9px 22px 0px rgba(255, 255, 255, 0.58);
  color:$font-primary-color;
  a, i, .dropdown-menu-nav {
    color: $font-primary-color;
    font-size: 1.1rem;
    opacity: .8;

    &:hover {
      transition: all 0.6s ease-out;
      color: $font-primary-color;
      border: none;
      opacity: 1;
    }
  }

  .dropdown-menu-nav {
    background-color: $secondary-color;
    -webkit-box-shadow: 0px 9px 22px 0px rgba(255, 255, 255, 0.58);
    -moz-box-shadow: 0px 9px 22px 0px rgba(255, 255, 255, 0.58);
    box-shadow: 0px 15px 30px 0px rgba(255, 255, 255, 0.58);
    @media (max-width: 992px) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: $marvel-secondary-color ;
    }
  }

}

.dropdown {
  cursor: pointer;
}

.dropdown-toggle::after {
  content: none !important;
}

.logo-navbar {
  height: auto;
  width: 100px;

}

.nav-link {
  font-size: 1.1rem;
}

.logo-navbar {
  width: 100px;
  height: auto;
}

.navbar-icon-top .navbar-nav .nav-link > .fa {
  position: relative;
  width: 36px;
  font-size: 40px;
  margin-bottom: 10px;

}

.navbar-icon-top .navbar-nav .nav-link > .dropdown-icon {
  position: relative;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
  font-size: 0.75rem;
  position: absolute;
  right: -20px;
  font-family: sans-serif;
}

.navbar-icon-top .navbar-nav .nav-link > .fa {
  top: 3px;
  line-height: 12px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
  top: -20px;
}

@media (min-width: 576px) {
  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 768px) {
  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 992px) {
  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 1200px) {
  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}
